.footer {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  padding: 96px 16px 16px 16px;
  font-size: 14px;
}

.consent {
  text-align: center;
}

.developer {
  text-align: right;
}
