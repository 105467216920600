.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  padding: 4px 16px;
  font-size: 48px;
  color: white;
  background-color: #009600;
}

.main {
  flex: 1;
}
